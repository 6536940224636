import React from "react";
import { Link } from "react-router-dom";
import "./styles/footer.css";

const Footer = () => {
    return (
        <React.Fragment>
            <div className="footer">
                <div className="footer-links">
                    <ul className="footer-nav-link-list">
                        <li className="footer-nav-link-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="footer-nav-link-item">
                            <Link to="/about">About</Link>
                        </li>
                        <li className="footer-nav-link-item">
                            <Link to="/projects">Projects</Link>
                        </li>
                        <li className="footer-nav-link-item">
                            <Link to="/SkillBars">Skills</Link>
                        </li>
                        <li className="footer-nav-link-item">
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-credits">
                    <div className="footer-credits-text">
                        <p>&copy; {new Date().getFullYear()} Ahmad-Alhourani.de. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Footer;
